import { render, staticRenderFns } from "./attribute-verification.vue?vue&type=template&id=71a91766&"
import script from "./attribute-verification.vue?vue&type=script&lang=js&"
export * from "./attribute-verification.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports